import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiArrowLeft, FiLock } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Loader from 'components/Loader';

import { useToast } from 'hooks/toast';

import logoImg from 'assets/logo_0.svg';
import errorImg from 'assets/error.png';

import Input from 'components/Input';
import Button from 'components/Button';

import getValidationErrors from 'utils/getValidationErrors';

import 'react-circular-progressbar/dist/styles.css';

import { checkVerificationCode, changePasswordWithCode } from 'hooks/forgot';
import { withMainLayout } from 'hocs/withMainLayout';

interface ForgotFormData {
  verification_code: string;
  password: string;
  password_confirmation: string;
}

const ForgotPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { token }: { token: string } = useParams();
  const [passwordIsChaged, setPasswordIsChanged] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenIsValid, setTokenIsValid] = useState(false);

  useEffect(() => {
    checkVerificationCode({ code: token })
      .then(status => {
        if (status) {
          setTokenIsValid(true);
        } else {
          setTokenIsValid(false);
        }
      })
      .catch(() => {
        setTokenIsValid(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  const handleSubmit = useCallback(
    async (data: ForgotFormData) => {
      try {
        formRef.current?.setErrors({});

        const { password, password_confirmation } = data;

        const schema = Yup.object().shape({
          password: Yup.string()
            .min(8, 'A senha deve conter no mínimo 8 caracteres.')
            .max(32, 'A senha pode conter até 32 caracteres.')
            .required('Código obrigatório'),
          password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), ''], 'As senhas não conferem.')
            .required('Confirmação de senha obrigatória.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const isChanged = await changePasswordWithCode({
          token,
          password,
          password_confirmation,
        });

        if (isChanged) {
          setPasswordIsChanged(isChanged);
        } else {
          setHasError(true);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Falha ao realizar a solicitação',
          description: 'Ocorreu um erro ao definir sua nova senha.',
        });
      }
    },
    [addToast, token],
  );

  return (
    <>
      {loading ? (
        <Loader size="20" />
      ) : (
        <>
          {tokenIsValid ? (
            <>
              {/* @ts-ignore */}
              <Form ref={formRef} onSubmit={handleSubmit}>
                {hasError ? (
                  <img
                    src={errorImg}
                    alt="Error"
                    style={{ width: 240, height: 240 }}
                  />
                ) : (
                  <img src={logoImg} alt="avatar" />
                )}

                {passwordIsChaged ? (
                  <>
                    <h2>Parabéns:</h2>
                    <p>Senha alterada com sucesso!.</p>
                  </>
                ) : (
                  <>
                    <h2>Redefinir senha</h2>

                    <div>
                      <Input
                        type="password"
                        name="password"
                        icon={FiLock}
                        placeholder="Informe a nova senha"
                      />
                      <Input
                        type="password"
                        name="password_confirmation"
                        icon={FiLock}
                        placeholder="Repita a nova senha"
                      />
                    </div>

                    <Button type="submit">Alterar Senha</Button>
                  </>
                )}

                {hasError && (
                  <>
                    <h2>Ops! Aconteceu um erro...</h2>
                    <p>Erro. Por favor tente novamente mais tarde.</p>
                  </>
                )}
              </Form>
              <Link to="/">
                <FiArrowLeft />
                Voltar para o login
              </Link>
            </>
          ) : (
            <>
              <img
                src={errorImg}
                alt="Error"
                style={{ width: 240, height: 240 }}
              />
              <h2>Ops! Aconteceu um erro...</h2>
              <p>
                O link não é mais válido. Solicite novamente a redefinição da
                sua senha.
              </p>
              <Link to="/">
                <FiArrowLeft />
                Voltar para o login
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withMainLayout(ForgotPassword);
