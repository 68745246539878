import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle, FiEyeOff, FiEye } from 'react-icons/fi';
import { useField } from '@unform/core';
import Button from 'components/Button';

import { Container, Error } from './styles';

type InputType = React.InputHTMLAttributes<HTMLInputElement>['type'];

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<React.PropsWithChildren<IconBaseProps>>;
}

const Input: React.FC<React.PropsWithChildren<InputProps>> = ({
  name,
  icon: Icon,
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [inputValue, setInputValue] = useState((rest?.value as string) ?? '');
  const [inputType, setInputType] = useState<InputType>(rest?.type);
  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    if (error) {
      clearError();
    }
  }, []); // eslint-disable-line

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
    if (error) {
      clearError();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const clearFieldError = (e: any) => {
    if (error) clearError();
    setInputValue(e.target.value);
  };

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      {Icon && <Icon size={20} />}

      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
        type={inputType}
        onChange={clearFieldError}
        value={inputValue}
      />

      {rest?.type !== 'email' &&
        (name === 'password' || name === 'password_confirmation') &&
        inputValue.length > 0 && (
          <Button
            className="svg-button-container"
            onClick={() =>
              setInputType(old => (old === 'password' ? 'text' : 'password'))
            }
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              padding: '0',
              width: 'max-content',
              height: 'max-content',
              margin: 0,
            }}
          >
            {inputType === 'password' ? (
              <FiEyeOff size={20} style={{ margin: 0 }} />
            ) : (
              <FiEye size={20} style={{ margin: 0 }} />
            )}
          </Button>
        )}

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
