/* eslint-disable @typescript-eslint/ban-types */
// @ts-nocheck
import React, { useRef, useCallback, useState } from 'react';
import { FiMail, FiLock, FiUser, FiPhone } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import Input from 'components/Input';
import Button from 'components/Button';
import { withMainLayout } from 'hocs/withMainLayout';
import {
  AnimationContainer,
  GridCol,
  GridContainer,
  GridRow,
} from 'hocs/withMainLayout/styles';
import Select from 'components/CustomSelect';
import { timezoneList } from 'config/timezoneList';
import CreatedAccountSuccess from 'pages/Register/ConfirmAccount/CreatedSuccess';
import {
  CreatePersonalVinculationAccountProps,
  createPersonalVinculationAccount,
} from 'requests/vinculation-collaborator';
import { useTranslation } from 'react-i18next';
import { VinculationDataProps } from '.';

const phoneMask = value => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const handlePhone = e => {
  let input = e.target;
  input.value = phoneMask(input.value);
};

interface VinculationCreateAccountProps {
  vinculation_data: VinculationDataProps | null;
}

const VinculationCreateAccount: React.FC<VinculationCreateAccountProps> = ({
  vinculation_data,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t, i18n } = useTranslation();
  const { addToast } = useToast();

  const schema = yup.object({
    name: yup
      .string()
      .required(t('register_page.validations.required'))
      .min(4, t('register_page.validations.min_char', { min: 4 }))
      .max(255, t('register_page.validations.max_char', { max: 255 })),
    phone: yup.string().max(15),
    born: yup.date().required(t('register_page.validations.required')),
    password: yup
      .string()
      .required(t('register_page.validations.required'))
      .min(8, t('register_page.validations.min_char', { min: 8 })),
    password_confirmation: yup
      .string()
      .required(t('register_page.validations.required'))
      .oneOf([yup.ref('password')], t('register_page.validations.unmatch_pass'))
      .min(8, t('register_page.validations.min_char', { min: 8 })),
    timezone: yup.string().required(t('register_page.validations.required')),
  });

  const currentLanguage = i18n.language;

  const handleSubmit = useCallback(
    async (data: CreatePersonalVinculationAccountProps) => {
      if (!vinculation_data?.linking_code || !vinculation_data?.email) {
        return;
      }

      setLoading(true);

      try {
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        const newData: CreatePersonalVinculationAccountProps = {
          ...data,
          born: data.born,
          accepted: true,
          linking_code: vinculation_data?.linking_code,
          email: vinculation_data?.email,
          email_confirmation: vinculation_data?.email,
        };

        await createPersonalVinculationAccount(newData);
        setSuccess(true);
      } catch (err) {
        addToast({
          type: 'error',
          title: t('register_page.errors.registration_title'),
          description: t('register_page.errors.registration_description'),
        });
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [], // eslint-disable-line
  );

  if (success) return <CreatedAccountSuccess />;

  return (
    <>
      <AnimationContainer width={572}>
        {/* @ts-ignore */}
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2 style={{ marginBottom: '5px' }}>
            {t('register_page.create_account_to_vinculation')}
          </h2>
          <p style={{ marginBottom: '15px' }}>
            {t('register_page.vinculation_description', {
              company: vinculation_data.company_name,
            })}
          </p>

          <GridContainer>
            <GridRow>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  icon={FiUser}
                  name="name"
                  placeholder={t('register_page.form.name')}
                  required
                  maxLength={255}
                  minLength={4}
                />
              </GridCol>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  name="born"
                  type="date"
                  placeholder={t('register_page.form.born_date')}
                  required
                  maxLength={10}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={12} lg={12} sm={12}>
                <Input
                  name="phone"
                  type="tel"
                  icon={FiPhone}
                  placeholder={t('register_page.form.phone')}
                  onKeyUp={e => handlePhone(e)}
                  maxLength={15}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={12} lg={12} sm={12}>
                <Input
                  name="email"
                  type="email"
                  icon={FiMail}
                  placeholder={t('register_page.form.email')}
                  disabled
                  required
                  value={vinculation_data?.email}
                  title={t('register_page.form.email_title')}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder={t('register_page.form.password')}
                  min={8}
                />
              </GridCol>
              <GridCol xs={6} lg={6} sm={6}>
                <Input
                  name="password_confirmation"
                  icon={FiLock}
                  type="password"
                  placeholder={t('register_page.form.repeat_password')}
                  min={8}
                />
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol xs={12} lg={12} sm={12}>
                <Select
                  name="timezone"
                  defaultValue={
                    currentLanguage === 'pt-BR'
                      ? 'America/Sao_Paulo'
                      : 'America/Costa_Rica'
                  }
                >
                  {timezoneList.map(timezone => (
                    <option key={timezone.timezone} value={timezone.timezone}>
                      {timezone.timezone} ({timezone.offset})
                    </option>
                  ))}
                </Select>
              </GridCol>
            </GridRow>
          </GridContainer>

          <Button loading={loading} disabled={loading} type="submit">
            {t('register_page.form.submit')}
          </Button>
        </Form>
      </AnimationContainer>
    </>
  );
};

export default withMainLayout(VinculationCreateAccount);
